<template>
	<div>
		<template>
			<v-toolbar flat color="primary" dark>
				<v-toolbar-title>{{contractPeriod.begin?contractPeriod.begin.replaceAll('-','.'):''}}. - {{contractPeriod.end?contractPeriod.end.replaceAll('-','.'):''}}. szerződési időszak</v-toolbar-title>
			</v-toolbar>
			<v-tabs :vertical="!isMobilephone">
				<v-tab>
					<v-icon left>fa-file-signature</v-icon>
					Alapadatok
				</v-tab>
				<v-tab>
					<v-icon left>fa-map-marker</v-icon>
					Egyesületek
				</v-tab>
				<v-tab-item>
					<!--Alapadatok-->
					<v-simple-table>
						<tbody>
							<tr>
								<th style="text-align: right">Kezdet</th>
								<td colspan="2" style="text-align: center">{{contractPeriod.begin}}</td>
							</tr>
							<tr>
								<th style="text-align: right">Vég</th>
								<td style="text-align: center">{{contractPeriod.end}}</td>
							</tr>
							<tr>
								<td style="text-align: center" colspan="2">
									<v-btn rounded :disabled="$store.getters.userRole<roles.nationalCoordinator"
										@click="dialogs.edit.show=true, dialogs.edit.changedContractPeriod.begin=contractPeriod.begin,dialogs.edit.changedContractPeriod.end=contractPeriod.end"
										color="blue">
										<v-icon>fa-edit</v-icon>Szerkesztés
									</v-btn>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-tab-item>
				<!--/Alapadatok-->

				<v-tab-item> <!--Egyesületek-->
					<clubs :contractPeriodId="$route.params.id"/>
				</v-tab-item>
				<!--/Egyesületek-->

			</v-tabs>

			<v-row justify="center">
				<v-dialog v-model="dialogs.edit.show" persistent max-width="600px">
					<v-card>
						<v-card-title>
							<span class="headline">Szerkesztés</span>
						</v-card-title>
						<v-card-text>
							<v-container>
                                <v-menu v-model="dialogs.edit.menus.begin" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dialogs.edit.changedContractPeriod.begin" label="Kezdő időpont"
                                            prepend-inner-icon="fa-calendar" readonly v-bind="attrs" v-on="on" rounded
                                            outlined />
                                    </template>
                                    <v-date-picker v-model="dialogs.edit.changedContractPeriod.begin" :first-day-of-week="1"
                                        @input="dialogs.edit.menus.begin = false" />
                                </v-menu>
                                <v-menu v-model="dialogs.edit.menus.end" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dialogs.edit.changedContractPeriod.end" label="Befejező időpont"
                                            prepend-inner-icon="fa-calendar" readonly v-bind="attrs" v-on="on" rounded
                                            outlined />
                                    </template>
                                    <v-date-picker v-model="dialogs.edit.changedContractPeriod.end" :first-day-of-week="1"
                                        @input="dialogs.edit.menus.end = false" />
                                </v-menu>

							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="dialogs.edit.show = false">
								Mégsem
							</v-btn>
							<v-btn color="blue darken-1" text @click="change()"
								v-if="dialogs.edit.changedContractPeriod.begin&&dialogs.edit.changedContractPeriod.end">
								Módosítás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

			</v-row>
		</template>
	</div>
</template>

<script>
import router from '../router';
import clubs from '../components/clubs.vue'
import addClub from '../components/contractPeriod-addClub.vue'
import roles from '../plugins/roles'
export default {
	name: 'contractPeriod',
	components: {
		clubs,
		addClub
	},
	data: function () {
		return {
			roles,
			contractPeriod: {},
			dialogs: {
				edit: {
					show: false,
					changedContractPeriod: { begin: '', end: '' },
					menus: {begin: false, end: false}
				}
			},
			tables: {
			}
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		change: function () {
			this.axios({ url: "contractPeriod/" + this.$route.params.id, method: "PUT", data: { changed: this.dialogs.edit.changedContractPeriod } }).then((response) => {
				if (response.data.success) {
					for (const [key, value] of Object.entries(this.dialogs.edit.changedContractPeriod)) {
						this.contractPeriod[key] = value
					}
					this.dialogs.edit.show = false;
				}
			})
		},
	},
	mounted() {
		this.axios({ url: "contractPeriod/" + this.$route.params.id, method: "GET" }).then((response) => {
			if (response.data.success) {
				this.contractPeriod = response.data.data.contractPeriod
			}
		})
	}
}
</script>
